<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6">{{$t('processes.agent')}}</h6>
        <div class="row">
          <AgentCard class="col-6" icon="accessibility" :showSeparator="false" v-if="linkedAgent" :agent="linkedAgent" @menuClick="onMenuClick" />
          <div class="add-agent-card col-6" v-else>
            <search-box v-if="searchBoxActivated"
              @input="onSelectLinkedObj"
              model_type="agent"
              :label="$t('processes.fields.agent.label')"
              :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
              :color="color" />
            <base-add-button v-else :label="$t('processes.add_agent')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="processColor title-h6">{{$t('processes.periodeProbatoire')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class=" col" @input="automatizeDateFin" v-bind="formInputProps('date_nomination')" :color="color"
                v-model="formData.date_nomination" />
              <base-input-date class="col" v-bind="formInputProps('date_fin')" :color="color"
                v-model="formData.date_fin" ref="dateFin" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6 class="processColor title-h6">{{$t('processes.avisDeLaRegion')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class=" col" v-bind="formInputProps('avis_region')" :color="color"
                v-model="formData.avis_region" />
            </div>
            <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaire_region.label')}}</q-item-label>
            <base-input-text class="q-px-sm row" placeholder="" label="" v-bind="formInputProps('commentaire_region')" :color="color"
              v-model="formData.commentaire_region" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6 class="processColor title-h6">{{$t('processes.siege')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class=" col" v-bind="formInputProps('avis_siege')" :color="color"
                v-model="formData.avis_siege" />
            </div>
            <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaire_siege.label')}}</q-item-label>
            <base-input-text class="q-px-sm row" placeholder="" label="" v-bind="formInputProps('commentaire_siege')" :color="color"
              v-model="formData.commentaire_siege" ref="label" />
            <div class="row q-gutter-sm">
              <base-input-select class=" col" v-bind="formInputProps('decision_dir_comm')" :color="color"
                v-model="formData.decision_dir_comm" />
            </div>
            <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaire_dir_comm.label')}}</q-item-label>
            <base-input-text class="q-px-sm row" placeholder="" label="" v-bind="formInputProps('commentaire_dir_comm')" :color="color"
              v-model="formData.commentaire_dir_comm" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item" v-if="isMandatFerme">
        <h6 class="processColor title-h6">{{$t('processes.bilan3mois')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_bilan_3M')" :color="color"
                  v-model="formData.date_reception_bilan_3M" />
                <base-input-date class="col" v-bind="formInputProps('date_comite_regional_3M')" :color="color"
                  v-model="formData.date_comite_regional_3M" ref="dateFin" />
              </div>
              <div class="row q-gutter-sm items-end">
                <base-input-date class="col" v-bind="formInputProps('date_relance_bilan_3M')" :color="color"
                  v-model="formData.date_relance_bilan_3M" />
                <q-btn color="process button-create-sujet-commit" class="col-6" size="sm"  @click="createSujet(3)" :disabled="!linkedAgent" icon-right="add_circle">{{$t('processes.create_sujet_commit')}}</q-btn>
              </div>
            </q-card-section>
          </q-card>
          <div>
            <div class="row q-gutter-md q-mt-sm">
              <div v-if="relatedLinks.filter(link => link.bilanLength === 3).length" class="column col">
                <div class="row q-gutter-sm q-pb-sm" v-for="relatedLink in relatedLinks.filter(link => link.bilanLength === 3)" :key="relatedLink.id">
                  <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-3" :actions="relatedLink.actions">
                    <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                    <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                    <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
                  </base-card>
                </div>
              </div>

              <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
                <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
              </div>

              <div v-else class="col-6">
                <q-card flat bordered class="q-pa-md">
                  <base-input-text v-bind="titleTemp" :color="color"
                      v-model="link.titre" ref="admin_note" />
                  <base-input-text v-bind="linkTemp" :color="color"
                      v-model="link.lien" ref="admin_note" />
                  <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink(3)">{{$t('form.ok')}}</q-btn>
                  <div style="clear: both;"></div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="processColor title-h6">{{$t('processes.bilan6mois')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_bilan_6M')" :color="color"
                  v-model="formData.date_reception_bilan_6M" />
                <base-input-date class="col" v-bind="formInputProps('date_comite_regional_6M')" :color="color"
                  v-model="formData.date_comite_regional_6M" ref="dateFin" />
              </div>
              <div class="row q-gutter-sm items-end">
                <base-input-date class="col" v-bind="formInputProps('date_relance_bilan_6M')" :color="color"
                  v-model="formData.date_relance_bilan_6M" />
                <q-btn color="process" class="button-create-sujet-commit col-6" size="sm" @click="createSujet(6)" :disabled="!linkedAgent" icon-right="add_circle">{{$t('processes.create_sujet_commit')}}</q-btn>
              </div>
            </q-card-section>
          </q-card>
          <div>
            <div class="row q-gutter-md q-mt-sm">
              <div v-if="relatedLinks.filter(link => link.bilanLength === 6).length" class="column col">
                <div class="row q-gutter-sm q-pb-sm" v-for="relatedLink in relatedLinks.filter(link => link.bilanLength === 6)" :key="relatedLink.id">
                  <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                    <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                    <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                    <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
                  </base-card>
                </div>
              </div>

              <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
                <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
              </div>

              <div v-else class="col-6">
                <q-card flat bordered class="q-pa-md">
                  <base-input-text v-bind="titleTemp" :color="color"
                      v-model="link.titre" ref="admin_note" />
                  <base-input-text v-bind="linkTemp" :color="color"
                      v-model="link.lien" ref="admin_note" />
                  <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink(6)">{{$t('form.ok')}}</q-btn>
                  <div style="clear: both;"></div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item" v-if="isMandatFerme">
        <h6 class="processColor title-h6">{{$t('processes.bilan9mois')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_bilan_9M')" :color="color"
                  v-model="formData.date_reception_bilan_9M" />
                <base-input-date class="col" v-bind="formInputProps('date_comite_regional_9M')" :color="color"
                  v-model="formData.date_comite_regional_9M" ref="dateFin" />
              </div>
              <div class="row q-gutter-sm items-end">
                <base-input-date class="col" v-bind="formInputProps('date_relance_bilan_9M')" :color="color"
                  v-model="formData.date_relance_bilan_9M" />
                <q-btn color="process button-create-sujet-commit" class="col-6" size="sm"  @click="createSujet(9)" :disabled="!linkedAgent" icon-right="add_circle">{{$t('processes.create_sujet_commit')}}</q-btn>
              </div>
            </q-card-section>
          </q-card>
          <div>
            <div class="row q-gutter-md q-mt-sm">
              <div v-if="relatedLinks.filter(link => link.bilanLength === 9).length" class="column col">
                <div class="row q-gutter-sm q-pb-sm" v-for="relatedLink in relatedLinks.filter(link => link.bilanLength === 9)" :key="relatedLink.id">
                  <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-9" :actions="relatedLink.actions">
                    <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                    <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                    <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
                  </base-card>
                </div>
              </div>

              <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
                <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
              </div>

              <div v-else class="col-6">
                <q-card flat bordered class="q-pa-md">
                  <base-input-text v-bind="titleTemp" :color="color"
                      v-model="link.titre" ref="admin_note" />
                  <base-input-text v-bind="linkTemp" :color="color"
                      v-model="link.lien" ref="admin_note" />
                  <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink(9)">{{$t('form.ok')}}</q-btn>
                  <div style="clear: both;"></div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item" v-if="!isMandatFerme">
        <h6 class="processColor title-h6">{{$t('processes.bilan12mois')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_bilan_12M')" :color="color"
                  v-model="formData.date_reception_bilan_12M" />
                <base-input-date class="col" v-bind="formInputProps('date_comite_regional_12M')" :color="color"
                  v-model="formData.date_comite_regional_12M" ref="dateFin" />
              </div>
              <div class="row q-gutter-sm items-end">
                <base-input-date class="col" v-bind="formInputProps('date_relance_bilan_12M')" :color="color"
                  v-model="formData.date_relance_bilan_12M" />
                <q-btn color="process button-create-sujet-commit" class="col-6" size="sm"  @click="createSujet(12)" :disabled="!linkedAgent" icon-right="add_circle">{{$t('processes.create_sujet_commit')}}</q-btn>
              </div>
            </q-card-section>
          </q-card>
          <div>
            <div class="row q-gutter-md q-mt-sm">
              <div v-if="relatedLinks.filter(link => link.bilanLength === 12).length" class="column col">
                <div class="row q-gutter-sm q-pb-sm" v-for="relatedLink in relatedLinks.filter(link => link.bilanLength === 12)" :key="relatedLink.id">
                  <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                    <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                    <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                    <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
                  </base-card>
                </div>
              </div>

              <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
                <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
              </div>

              <div v-else class="col-6">
                <q-card flat bordered class="q-pa-md">
                  <base-input-text v-bind="titleTemp" :color="color"
                      v-model="link.titre" ref="admin_note" />
                  <base-input-text v-bind="linkTemp" :color="color"
                      v-model="link.lien" ref="admin_note" />
                  <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink(12)">{{$t('form.ok')}}</q-btn>
                  <div style="clear: both;"></div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item" v-if="!isMandatFerme">
        <h6 class="processColor title-h6">{{$t('processes.bilan18mois')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_bilan_18M')" :color="color"
                  v-model="formData.date_reception_bilan_18M" />
                <base-input-date class="col" v-bind="formInputProps('date_comite_regional_18M')" :color="color"
                  v-model="formData.date_comite_regional_18M" ref="dateFin" />
              </div>
              <div class="row q-gutter-sm items-end">
                <base-input-date class="col" v-bind="formInputProps('date_relance_bilan_18M')" :color="color"
                  v-model="formData.date_relance_bilan_18M" />
                <q-btn color="process" class="col-6 button-create-sujet-commit" size="sm"  @click="createSujet(18)" :disabled="!linkedAgent" icon-right="add_circle">{{$t('processes.create_sujet_commit')}}</q-btn>
              </div>
            </q-card-section>
          </q-card>
          <div>
            <div class="row q-gutter-md q-mt-sm">
              <div v-if="relatedLinks.filter(link => link.bilanLength === 18).length" class="column col">
                <div class="row q-gutter-sm q-pb-sm" v-for="relatedLink in relatedLinks.filter(link => link.bilanLength === 18)" :key="relatedLink.id">
                  <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                    <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                    <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                    <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
                  </base-card>
                </div>
              </div>

              <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
                <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
              </div>

              <div v-else class="col-6">
                <q-card flat bordered class="q-pa-md">
                  <base-input-text v-bind="titleTemp" :color="color"
                      v-model="link.titre" ref="admin_note" />
                  <base-input-text v-bind="linkTemp" :color="color"
                      v-model="link.lien" ref="admin_note" />
                  <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink(18)">{{$t('form.ok')}}</q-btn>
                  <div style="clear: both;"></div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.sujetsLies')}}</h6>
        <ul class="">
          <li v-for="sujet in sujets" :key="sujet.id">
            <base-card v-bind="sujet" draggable @menuClick="onMenuClick">
              <q-avatar slot='avatar' size="3rem" color="per" icon="assignment_late" />

              <q-item class="items-start q-pa-none">
                <q-item-section v-if="sujet">
                  <q-item-label caption>{{$formatDate(sujet.created_at)}}</q-item-label>
                  <q-item-label>
                    <strong><router-link :to="{ name: 'sujet-show', params: { id: sujet.id } }">{{sujet.label}}</router-link></strong>
                  </q-item-label>

                  <div class="row">
                    <div class="col" v-for="obj in sujet.linkedObjects" :key="obj.id">
                      <q-item class="q-pa-none">
                        <q-item-section avatar>
                          <q-avatar :icon="obj.icon" size="2rem" color="reseau" />
                        </q-item-section>
                        <q-item-section>
                          <q-item-label caption>{{obj.label}}</q-item-label>
                        </q-item-section>
                      </q-item>
                    </div>
                  </div>
                </q-item-section>
              </q-item>
            </base-card>
          </li>
        </ul>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess" :disabled="!isUpdate">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import { AgentCard } from '../../../components/agents'
import { getLinkedObjectsFromModel } from '../../../boot/helpers'

export default {
  mixins: [BaseForm],
  components: { AgentCard },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      relatedPeriodeProbatoire: [],
      searchBoxActivated: false,
      linkedAgent: null,
      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      },
      sujets: [],
      isUpdate: this.$route.params.id
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentPeriodeProbatoireProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    isMandatFerme () {
      return this.$route.meta.isMandatFerme
    }
  },

  watch: {
    isMandatFerme: {
      handler (newVal, oldVal) {
        if (this.$route.params.id) {
          this.$store.dispatch('processes/fetchProcess', { id: this.$route.params.id, type: 'periodeProbatoire' }).then(() => {
            console.log(this.formData)
            if (newVal) {
              this.$store.dispatch('processes/fetchFormFields', { type: 'periodeProbatoire', droits_title: 'MANDAT_FERME' })
            } else {
              this.$store.dispatch('processes/fetchFormFields', 'periodeProbatoire')
            }
          })
        } else {
          if (newVal) {
            this.$store.dispatch('processes/fetchFormFields', { type: 'periodeProbatoire', droits_title: 'MANDAT_FERME' })
          } else {
            this.$store.dispatch('processes/fetchFormFields', 'periodeProbatoire')
          }
        }
        if (newVal) {
          this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_mandat_ferme'))
        } else {
          this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_periode_probatoire'))
        }
      },
      immediate: true
    },

    relatedLinks (newVal, oldVal) {
      let tempFormData = {}
      tempFormData[`lien_bilan_3M`] = []
      tempFormData[`lien_bilan_6M`] = []
      tempFormData[`lien_bilan_9M`] = []
      tempFormData[`lien_bilan_12M`] = []
      tempFormData[`lien_bilan_18M`] = []
      for (let i = 0; i < newVal.length; i++) {
        const { bilanLength, titre, lien, id } = newVal[i]
        tempFormData[`lien_bilan_${bilanLength}M`].push({ bilanLength, titre, lien, id })
      }

      this.formData[`lien_bilan_3M`] = JSON.stringify(tempFormData[`lien_bilan_3M`])
      this.formData[`lien_bilan_6M`] = JSON.stringify(tempFormData[`lien_bilan_6M`])
      this.formData[`lien_bilan_9M`] = JSON.stringify(tempFormData[`lien_bilan_9M`])
      this.formData[`lien_bilan_12M`] = JSON.stringify(tempFormData[`lien_bilan_12M`])
      this.formData[`lien_bilan_18M`] = JSON.stringify(tempFormData[`lien_bilan_18M`])
    },

    linkedAgent (newVal, oldVal) {
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'agent')
      if (newVal) this.formData.related_objects.push({ model_type: 'agent', model_id: newVal.id })
    },

    sujets (newVal, oldVal) {
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'sujet')
      if (newVal) this.formData.related_objects.push(...newVal.map(({ id }) => ({ model_type: 'sujet', model_id: id })))
    },

    formFields: {
      handler (newVal, oldVal) {
        this.formData = {}
        this.formData.model_type = 'periodeProbatoire'
        this.formData.related_objects = []
        if (!newVal || !this.currentPeriodeProbatoireProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentPeriodeProbatoireProcess[id]) : this.currentPeriodeProbatoireProcess[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set agent
          if (this.currentPeriodeProbatoireProcess.agents) {
            this.linkedAgent = this.currentPeriodeProbatoireProcess.agents.map(agent => ({
              ...agent,
              label: agent['TITULAIRE'],
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: agent.id } } }],
              is_favorite: !!this.favorites && !!this.favorites['agent'] &&
                this.favorites['agent'].some(favorite => favorite.id === agent.id),
              model: { model_type: 'agent', model_id: agent.id }
            }))[0]
          }

          // Set links
          let tempLienBilan3m = this.currentPeriodeProbatoireProcess.lien_bilan_3M ? JSON.parse(this.currentPeriodeProbatoireProcess.lien_bilan_3M).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          let tempLienBilan6m = this.currentPeriodeProbatoireProcess.lien_bilan_6M ? JSON.parse(this.currentPeriodeProbatoireProcess.lien_bilan_6M).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          let tempLienBilan9m = this.currentPeriodeProbatoireProcess.lien_bilan_9M ? JSON.parse(this.currentPeriodeProbatoireProcess.lien_bilan_9M).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          let tempLienBilan12m = this.currentPeriodeProbatoireProcess.lien_bilan_12M ? JSON.parse(this.currentPeriodeProbatoireProcess.lien_bilan_12M).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          let tempLienBilan18m = this.currentPeriodeProbatoireProcess.lien_bilan_18M ? JSON.parse(this.currentPeriodeProbatoireProcess.lien_bilan_18M).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []

          this.relatedLinks = [...tempLienBilan3m, ...tempLienBilan6m, ...tempLienBilan9m, ...tempLienBilan12m, ...tempLienBilan18m]

          // Set sujets
          if (this.currentPeriodeProbatoireProcess.sujets) {
            this.sujets = this.currentPeriodeProbatoireProcess.sujets.map(sujet => ({
              ...sujet,
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'sujet-detach', params: { id: sujet.id } } }]
            }))
            this.sujets.forEach(sujet => {
              sujet.linkedObjects = getLinkedObjectsFromModel(sujet)
              sujet.linkedObjects.forEach(linked => {
                linked.label = this.linkedObjectLabel(linked.obj, linked.type)
                linked.icon = this.linkedObjectIcon(linked.type).name
              })
            })
          }

          // Set nomination
          if (this.currentPeriodeProbatoireProcess.nomination) {
            this.formData.related_objects.push({
              model_type: 'nomination',
              model_id: this.currentPeriodeProbatoireProcess.nomination.id
            })
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_periode_probatoire'), to: { name: 'suivi-de-periode-probatoire-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-periode-probatoire-form', params: { id: this.$route.params.id } } } : {}
    ])

    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    // if (currentRoute.params.id) {
    //   store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'periodeProbatoire' }).then(() => {
    //     store.dispatch('processes/fetchFormFields', 'periodeProbatoire')
    //   })
    // } else {
    //   store.dispatch('processes/fetchFormFields', 'periodeProbatoire')
    // }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    linkedObjectLabel (obj, type) {
      if (type === 'agent') { return `${obj['PRENOM']} ${obj['NOMITV']}` }
      if (type === 'wallet') { return obj['NOMPTF'] }
      if (type === 'entity') { return obj['label'] }
      return undefined
    },

    linkedObjectIcon (type) {
      if (type === 'agent') { return { name: 'accessibility', color: 'reseau' } }
      if (type === 'wallet') { return { name: 'account_balance_wallet', color: 'reseau' } }
      if (type === 'entity') { return { name: 'account_balance_wallet', color: 'reseau' } }
      return undefined
    },

    automatizeDateFin (dateNomination) {
      if (!dateNomination) return
      let d = new Date(dateNomination)
      let year = d.getFullYear()
      let month = d.getMonth()
      let day = d.getDate()

      const yearToAdd = this.isMandatFerme ? 3 : 2
      let dateFin = new Date(year + yearToAdd, month, day - 1)

      let newYear = dateFin.getFullYear()
      let newMonth = dateFin.getMonth() + 1 < 10 ? `0${(dateFin.getMonth() + 1)}` : dateFin.getMonth() + 1
      let newDate = dateFin.getDate()
      let newDateFormated = String(newYear + '-' + newMonth + '-' + newDate)

      this.formData.date_fin = newDateFormated
    },
    createSujet (bilanLength) {
      let relatedFiche = { model_type: 'periodeProbatoires', model_id: this.$route.params.id }
      let relatedAgent = { model_type: 'agent', model_id: this.linkedAgent.id }

      const regionId = 6
      const label = `${this.linkedAgent.label} - bilan ${bilanLength} mois`

      const statut = 'A_PLANIFIER'
      let date_limite = null
      let type_id = null

      let getDateNominationAddedMonthAndSetDayEndMonth = (monthToAdd) => {
        let dateNomination = new Date(this.formData.date_nomination)
        dateNomination.setMonth(dateNomination.getMonth() + monthToAdd)

        let result = new Date(dateNomination.getFullYear(), dateNomination.getMonth() + 1, 0)
        return `${result.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`
      }

      if (this.isMandatFerme) {
        type_id = 21
      } else {
        switch (bilanLength) {
          case 12:
            type_id = 22
            date_limite = getDateNominationAddedMonthAndSetDayEndMonth(14)
            break
          case 18:
            type_id = 23
            date_limite = getDateNominationAddedMonthAndSetDayEndMonth(19)
            break
          case 6:
            type_id = 24
            date_limite = getDateNominationAddedMonthAndSetDayEndMonth(8)
            break
        }
      }

      this.$store.dispatch('sujets/saveSujet', { statut, date_limite, type_id, region_id: regionId, label: label, related_objects: [relatedFiche, relatedAgent] }).then(data => {
        if (!this.formData.related_objects) this.formData.related_objects = []

        // Add in form data
        this.sujets.push({
          label: label,
          id: data.id,
          actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'sujet-detach', params: { id: data.id } } }]
        })
        this.hasChanged = true
        this.notifySuccess('processes.success')
      })
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      } else if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      } else if (name === 'sujet-detach') {
        this.sujets = this.sujets.filter(sujet => sujet.id !== params.id)
      }
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id, generated_id }) {
      console.log(generated_id)
      this.isUpdate = true
      if (id) this.formData.id = id
      if (generated_id) this.$router.push({ name: 'suivi-de-mandat-ferme-form', params: { id: generated_id } })
      else if (!this.$route.params.id) this.$router.push({ name: generated_id ? 'suivi-de-mandat-ferme-form' : 'suivi-de-periode-probatoire-form', params: { id } })
    },
    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'periodeProbatoire', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },
    addLink (bilanLength) {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]
      copiedObject.bilanLength = bilanLength

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .processColor
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0
    top 7px
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
  ul
    list-style: none
    padding: 0
    li
      margin-bottom: 10px
  .button-create-sujet-commit
    height 35px
</style>
